import(/* webpackMode: "eager", webpackExports: ["AccidentSeverityByYear"] */ "/vercel/path0/components/charts/accident-severity-by-year.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AccidentsByHour"] */ "/vercel/path0/components/charts/accidents-by-hour.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AccidentsByYearByMonth"] */ "/vercel/path0/components/charts/accidents-by-year-by-month.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AccidentsByYear"] */ "/vercel/path0/components/charts/accidents-by-year.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Fatalities"] */ "/vercel/path0/components/charts/fatalities.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Injuries"] */ "/vercel/path0/components/charts/injuries.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/common/ImageComponent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SmallContactForm"] */ "/vercel/path0/components/small-contact-form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/@sanity+next-loader@1.1.8_@sanity+client@6.22.5_next@14.2.18_@babel+core@7.24.5_react-dom@18._frljkxxqi6rrxw2os3khao5tzu/node_modules/@sanity/next-loader/dist/client-components/live-stream.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/@sanity+next-loader@1.1.8_@sanity+client@6.22.5_next@14.2.18_@babel+core@7.24.5_react-dom@18._frljkxxqi6rrxw2os3khao5tzu/node_modules/@sanity/next-loader/dist/client-components/live.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-sanity@9.8.16_@sanity+client@6.22.5_@sanity+icons@3.4.0_react@18.3.1__@sanity+types@3.64_gtmjsthemwqz7bqjmqkawbrxcu/node_modules/next-sanity/dist/visual-editing/client-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.18_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.18_@babel+core@7.24.5_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/Accident-347.png");
